<template>
  <form class="Register content" @submit.prevent="send">

    <div>
      <h1 class="mb-4" v-html="$t('register.title')" />
    </div>

    <div>
      <label for="name">{{ $t('register.name') }}</label>
      <input
        id="name"
        v-model="$store.state.form.name"
        type="text"
        :placeholder="$t('register.name_placeholder')"
        required
      >
    </div>

    <div>
      <label for="mail">{{ $t('register.mail') }}</label>
      <input
        id="mail"
        v-model="$store.state.form.mail"
        type="email"
        :placeholder="$t('register.mail_placeholder')"
        required
      >
    </div>

    <!--
    <div>
      <label for="tel">{{ $t('register.tel') }}</label>
      <input
        id="tel"
        v-model="$store.state.form.tel"
        type="tel"
        :placeholder="$t('register.tel_placeholder')"
        required
      >
    </div>
    -->

    <!--
    <div class="mt-4">
      <input
        id="sell"
        v-model="$store.state.form.sell"
        class="inline"
        type="checkbox"
      >
      <label for="sell" class="inline">{{ $t('register.sell') }}</label>
    </div>
    -->

    <go to="/terms" class="mt-auto underline text-center">
      {{ $t('register.terms') }}
    </go>

    <button class="mt-4">
      {{ $t('register.action') }}
    </button>

  </form>
</template>

<script>
export default {
  mounted() {
    const { lang } = this.$route.query;
    if (lang) this.$i18n.locale = lang;
  },
  methods: {
    send() {
      this.axios.post(
        `https://admin.bikeshuffle.jkweb.dev/${this.$i18n.locale}/ajax/forms/new_request/notify`,
        {
          form: 'Game',
          language: this.$i18n.locale,
          score: this.$store.state.score,
          first_name: this.$store.state.form.name,
          email: this.$store.state.form.mail,
          sell: this.$store.state.form.sell,
          // tel: this.$store.state.form.tel,
        },
      ).then((resp) => {
        // eslint-disable-next-line no-alert
        if (resp.data.type === 'error') alert(resp.data.message);
        else this.$router.push({ path: '/thanks' });
      });
    },
  },
};
</script>
